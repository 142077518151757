<template>
  <!-- <div class="h-screen flex w-full bg-img">
    <div
      class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center"
    >
      <img
        src="../assets/images/pages/graphic-2.png"
        alt="graphic-maintenance"
        class="mx-auto mb-4"
      />
      <h1 class="mb-6 text-5xl d-theme-heading-color">MR RECO</h1>
      <p class="mb-6 d-theme-text-inverse">
        Upload an Excel file and automate your process.
      </p>
      <input type="file" id="fileUpload" />
      <vs-button
        style="margin-top: 3%"
        color="dark"
        id="upload"
        type="filled"
        @click="Upload"
        >Upload</vs-button
      >
      <vs-progress
        v-if="uploadingstatus"
        style="margin-top: 5%"
        :percent="completedpercentage"
        :color="color"
      ></vs-progress>
    </div>
  </div> -->
  <div>
    <vx-card actionable class="cardx" title="MR RECO">
      <vs-row style="margin-top: 2%">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="3"
        >
          <vs-button color="dark" type="border" @click="uploadExcel()"
            >Upload Excel File</vs-button
          >
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="9">
        <vs-dropdown class="gap" vs-custom-content vs-trigger-click :key="dropdownKey">
            <vs-button color="dark" type="gradient" icon="search"></vs-button>
            <vs-dropdown-menu class="loginx">
              <vs-row vs-w="12" style="margin-bottom: 2%">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="8"
                >
                  <vs-input v-model="year_search" placeholder="Year" />
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-end"
                  vs-w="4"
                >
                  <vs-button
                    color="dark"
                    type="gradient"
                    icon="search"
                    @click="analyzepagination()"
                  ></vs-button>
                </vs-col>
              </vs-row>
              <vs-row vs-w="12" style="margin-bottom: 2%">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="8"
                >
                  <vs-input v-model="mr_number_search" placeholder="MR" />
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-end"
                  vs-w="4"
                >
                  <vs-button
                    color="dark"
                    type="gradient"
                    icon="search"
                    @click="analyzepagination()"
                  ></vs-button>
                </vs-col>
              </vs-row>
              <vs-row vs-w="12" style="margin-bottom: 2%">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="8"
                >
                  <vs-input
                    v-model="amount_per_bank_search"
                    placeholder="Bank"
                  />
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-end"
                  vs-w="4"
                >
                  <vs-button
                    color="dark"
                    type="gradient"
                    icon="search"
                    @click="analyzepagination()"
                  ></vs-button>
                </vs-col>
              </vs-row>
              <!-- <vs-row vs-w="12" style="margin-bottom: 2%">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="8"
                >
                  <vs-input v-model="mr_number_search" placeholder="MR" />
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-end"
                  vs-w="4"
                >
                  <vs-button
                    color="dark"
                    type="gradient"
                    icon="search"
                    @click="analyzepagination()"
                  ></vs-button>
                </vs-col>
              </vs-row> -->
              <vs-row vs-w="12" style="margin-bottom: 2%">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="8"
                >
                  <vs-input v-model="amount_per_mf_search" placeholder="MF" />
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-end"
                  vs-w="4"
                >
                  <vs-button
                    color="dark"
                    type="gradient"
                    icon="search"
                    @click="analyzepagination()"
                  ></vs-button>
                </vs-col>
              </vs-row>
              <vs-row vs-w="12" style="margin-bottom: 2%">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="8"
                >
                  <vs-input
                    v-model="difference_search"
                    placeholder="Difference"
                  />
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-end"
                  vs-w="4"
                >
                  <vs-button
                    color="dark"
                    type="gradient"
                    icon="search"
                    @click="analyzepagination()"
                  ></vs-button>
                </vs-col>
              </vs-row>
              <vs-row vs-w="12" style="margin-bottom: 2%">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="8"
                >
                  <vs-input
                    type="number"
                    v-model="difference_percentage_search"
                    placeholder="Difference (%)"
                  />
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-end"
                  vs-w="4"
                >
                  <vs-button
                    color="dark"
                    type="gradient"
                    icon="search"
                    @click="analyzepagination()"
                  ></vs-button>
                </vs-col>
              </vs-row>
              <vs-row vs-w="12" style="margin-bottom: 2%">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="8"
                >
                  <v-select
                    class="w-full"
                    placeholder="Flag"
                    v-model="flag_search"
                    :clearable="true"
                    :options="flagOptions"
                    label="name"
                    :reduce="(flag) => flag.value"
                  ></v-select>
                  <!-- <vs-input v-model="flag_search" placeholder="Flag" /> -->
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-end"
                  vs-w="4"
                >
                  <vs-button
                    color="dark"
                    type="gradient"
                    icon="search"
                    @click="analyzepagination()"
                  ></vs-button>
                </vs-col>
              </vs-row>
              <vs-row vs-w="12" style="margin-bottom: 2%">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="8"
                >
                  <vs-input v-model="reason_search" placeholder="Reason" />
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-end"
                  vs-w="4"
                >
                  <vs-button
                    color="dark"
                    type="gradient"
                    icon="search"
                    @click="analyzepagination()"
                  ></vs-button>
                </vs-col>
              </vs-row>
              <vs-row vs-w="12" style="margin-bottom: 2%">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="8"
                >
                  <vs-input v-model="comments_search" placeholder="Comments" />
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-end"
                  vs-w="4"
                >
                  <vs-button
                    color="dark"
                    type="gradient"
                    icon="search"
                    @click="analyzepagination()"
                  ></vs-button>
                </vs-col>
              </vs-row>
              <vs-row vs-w="12" style="margin-bottom: 2%">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="8"
                >
                  <vs-input v-model="adj_amount_search" placeholder="ADJ" />
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-end"
                  vs-w="4"
                >
                  <vs-button
                    color="dark"
                    type="gradient"
                    icon="search"
                    @click="analyzepagination()"
                  ></vs-button>
                </vs-col>
              </vs-row>
              <vs-row vs-w="12" style="margin-bottom: 2%">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="8"
                >
                  <vs-input
                    type="number"
                    v-model="adjustment_percentage_search"
                    placeholder="ADJ (%)"
                  />
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-end"
                  vs-w="4"
                >
                  <vs-button
                    color="dark"
                    type="gradient"
                    icon="search"
                    @click="analyzepagination()"
                  ></vs-button>
                </vs-col>
              </vs-row>
              <vs-row vs-w="12" style="margin-bottom: 2%">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="8"
                >
                  <vs-input
                    type="number"
                    v-model="difference_percentage_above_search"
                    placeholder="Difference Above"
                  />
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-end"
                  vs-w="4"
                >
                  <vs-button
                    color="dark"
                    type="gradient"
                    icon="search"
                    @click="analyzepagination()"
                  ></vs-button>
                </vs-col>
              </vs-row>
              <vs-row vs-w="12" style="margin-bottom: 2%">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="8"
                >
                  <vs-input
                    type="number"
                    v-model="difference_percentage_below_search"
                    placeholder="Difference Below"
                  />
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-end"
                  vs-w="4"
                >
                  <vs-button
                    color="dark"
                    type="gradient"
                    icon="search"
                    @click="analyzepagination()"
                  ></vs-button>
                </vs-col>
              </vs-row>
            </vs-dropdown-menu>
          </vs-dropdown>
          <vs-button
            style="margin-left: 1%"
            color="dark"
            type="gradient"
            icon="refresh"
            @click="refresh()"
          ></vs-button>
        </vs-col>
      </vs-row>
      <vs-table :data="mrRecoData" style="margin-top: 2%">
        <template slot="thead">
          <vs-th>Year</vs-th>
          <vs-th>Mr</vs-th>
          <vs-th>Count (Bank)</vs-th>
          <vs-th>Bank</vs-th>
          <vs-th>Count (MF)</vs-th>
          <vs-th>MF</vs-th>
          <vs-th>Difference</vs-th>
          <vs-th>Difference (%)</vs-th>
          <vs-th>Flag</vs-th>
          <vs-th>Reason</vs-th>
          <vs-th>Comments</vs-th>
          <vs-th>ADJ AMT</vs-th>
          <vs-th>ADJ (%)</vs-th>
          <vs-th>Actions</vs-th>
        </template>

        <template slot-scope="{ data }">
          <tbody ref="tbody">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>
                <div>{{ tr.year }}</div>
              </vs-td>
              <vs-td>
                <div>{{ tr.mr }}</div>
              </vs-td>
              <vs-td>
                <div>{{ tr.count_per_bank }}</div>
              </vs-td>
              <vs-td>
                <div>{{ tr.amount_per_bank }}</div>
              </vs-td>
              <vs-td>
                <div>{{ tr.count_per_mf }}</div>
              </vs-td>
              <vs-td>
                <div>{{ tr.amount_per_mf }}</div>
              </vs-td>
              <vs-td>
                <div>{{ tr.difference }}</div>
              </vs-td>
              <vs-td>
                <div>{{ tr.difference_percentage }}%</div>
              </vs-td>
              <vs-td>
                <div>{{ formatFlagText(tr.flag) }}</div>
              </vs-td>
              <vs-td>
                <div>{{ tr.reason }}</div>
              </vs-td>
              <vs-td>
                <div>{{ tr.comments }}</div>
              </vs-td>
              <vs-td>
                <div>{{ tr.adj_amount }}</div>
              </vs-td>
              <vs-td v-if="tr.adj_percentage === null">
                <div>{{ tr.adj_percentage }}</div>
              </vs-td>
              <vs-td v-else>
                <div>{{ tr.adj_percentage }}%</div>
              </vs-td>
              <vs-td>
                <div>
                  <vs-button
                    v-if="tr.flag !== 'match'"
                    color="dark"
                    type="border"
                    @click="EditReason(tr)"
                    >Edit</vs-button
                  >
                </div>
              </vs-td>
            </vs-tr>
          </tbody>
        </template>
      </vs-table>
      <vs-pagination
        :total="totalPages"
        v-model="currentPage"
        style="margin-top: 2%"
      ></vs-pagination>

      <vs-popup class="holamundo" title="Excel Upload" :active.sync="open">
        <vs-row vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <b class="mb-6 d-theme-text-inverse">
              Upload an Excel file and automate the process
            </b>
          </vs-col>
        </vs-row>
        <vs-row vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <input type="file" id="fileUpload" />
          </vs-col>
        </vs-row>
        <vs-row vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <vs-button
              style="margin-top: 3%"
              color="dark"
              id="upload"
              type="filled"
              @click="Upload"
              >Upload</vs-button
            >
          </vs-col>
        </vs-row>
        <vs-row vs-w="12" style="margin-top:3%">
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <b class="mb-6 d-theme-text-inverse">
              {{ completedRow }} / {{ totalRows }}
            </b>
          </vs-col>
        </vs-row>
        <vs-progress
          v-if="uploadingstatus"
          :percent="completedpercentage"
          :color="color"
        ></vs-progress>
      </vs-popup>

      <vs-popup
        class="holamundo"
        title="Reason and comments"
        :active.sync="openEditOptions"
      >
        <vs-row vs-w="12">
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <b style="margin-top: 17px" class="mb-6 d-theme-text-inverse">
              MR
            </b>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <b style="margin-top: 17px" class="mb-6 d-theme-text-inverse">
              Bank
            </b>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <b style="margin-top: 17px" class="mb-6 d-theme-text-inverse">
              MF
            </b>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <b style="margin-top: 17px" class="mb-6 d-theme-text-inverse">
              Difference
            </b>
          </vs-col>
        </vs-row>
        <vs-row vs-w="12" style="margin-bottom: 3%">
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <!-- <b style="margin-top: 17px" class="mb-6 d-theme-text-inverse"> -->
            {{ this.editObj.mr }}
            <!-- </b> -->
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <!-- <b style="margin-top: 17px" class="mb-6 d-theme-text-inverse"> -->
            {{ this.editObj.amount_per_bank }}
            <!-- </b> -->
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <!-- <b style="margin-top: 17px" class="mb-6 d-theme-text-inverse"> -->
            {{ this.editObj.amount_per_mf }}
            <!-- </b> -->
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <!-- <b style="margin-top: 17px" class="mb-6 d-theme-text-inverse"> -->
            {{ this.editObj.difference }}
            <!-- </b> -->
          </vs-col>
        </vs-row>

        <vs-row vs-w="12" style="margin-bottom: 3%">
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-w="3"
          >
            <b style="margin-top: 17px" class="mb-6 d-theme-text-inverse">
              Reason
            </b>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="9"
          >
            <!-- <b class="mb-6 d-theme-text-inverse"> -->
            <v-select
              :options="reasons"
              placeholder="Reason"
              v-model="reason"
              style="width:100%"
            />
            <!-- </b> -->
          </vs-col>
        </vs-row>
        <vs-row vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-w="3"
          >
            <b class="mb-6 d-theme-text-inverse">
              Comments
            </b>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="9"
          >
            <vs-textarea
              :disabled="comments_disabled"
              label="Comments"
              v-model="comments"
            />
          </vs-col>
        </vs-row>
        <vs-row vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-w="3"
          >
            <b style="margin-top: 20px" class="mb-6 d-theme-text-inverse">
              Amount
            </b>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="9"
          >
            <vs-input
              :disabled="adj_amount_disabled"
              class="inputx"
              placeholder="Adj Amount"
              type
              v-model="adj_amount"
            />
            <!-- <vs-textarea label="Comments" v-model="comments" /> -->
          </vs-col>
        </vs-row>
        <vs-row vs-w="12" style="margin-top: 3%">
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <vs-button color="dark" type="border" @click="saveReason()"
              >Save</vs-button
            >
          </vs-col>
        </vs-row>
      </vs-popup>
    </vx-card>
  </div>
</template>

<script src="xlsx.full.min.js"></script>
<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
// import $ from "jquery";
import EventBus from "../components/eventbus.js";
import constants from "../../constants.json";
import router from "@/router";
import vSelect from "vue-select";
import XLSX from "xlsx";
// import ajax from "ajax";
export default {
  mounted() {
    this.fetchMrRecoData();
  },
  data() {
    return {
      dropdownKey: 0,
      open: false,
      openEditOptions: false,
      mrRecoData: [],
      currentPage: 1,
      totalPages: 1,
      reasons: [],
      option1: [
        "Suspense",
        "Director loans",
        "Transfer from OD account",
        "Flight refunds",
        "NEFT bounce back",
        "Candidate cheque bounce",
        "University EWB",
        "IIML EWB",
        "Petty reversals",
        "Other receipts",
      ],
      option2: [
        "Payment gateway charges (Local)",
        "Forex and bank charges (Int)",
      ],
      flagOptions: [
        {
          name: "Match",
          value: "match",
        },
        {
          name: "Match(Flag 1)",
          value: "match_flag_1",
        },
        {
          name: "Match(Flag 2)",
          value: "match_flag_2",
        },
        {
          name: "Flag 1",
          value: "flag_1",
        },
        {
          name: "Flag 2",
          value: "flag_2",
        },
      ],
      reason: "",
      comments: "",
      adj_amount: "",
      completedpercentage: 0,
      file: "",
      notuploaded: true,
      uploadingstatus: true,
      color: "success",
      totalNumber: 0,
      completedNumber: 0,
      editObj: {},
      adj_amount_disabled: false,
      comments_disabled: false,
      totalRows: 0,
      completedRow: 0,
      year_search: "",
      mr_number_search: "",
      amount_per_bank_search: "",
      adjusted_bank_amt_search: "",
      amount_per_mf_search: "",
      difference_search: "",
      difference_percentage_search: "",
      flag_search: "",
      reason_search: "",
      comments_search: "",
      adj_amount_search: "",
      adjustment_percentage_search: "",
      difference_percentage_above_search: "",
      difference_percentage_below_search: "",
    };
  },
  components: {
    Datepicker,
    "v-select": vSelect,
  },
  watch: {
    currentPage() {
      this.fetchMrRecoData();
    },
    open(value) {
      if (!value) {
        this.fetchMrRecoData();
      }
    },
    reason(value) {
      // console.log(value);
      if (value == null || value == "") {
        return;
      }
      this.comments_disabled = false;
      this.comments = "";
      this.adj_amount_disabled = true;
      this.adj_amount = "";
      if (value === "Suspense") {
        this.adj_amount = null;
      } else if (
        value === "Payment gateway charges (Local)" ||
        value === "Forex and bank charges (Int)"
      ) {
        this.adj_amount_disabled = false;
      } else {
        this.calculateAmount();
      }
    },
  },
  methods: {
    formatFlagText(text) {
      switch (text) {
        case "match":
          return "Match";
          break;
        case "match_flag_1":
          return "Match (Flag 1)";
          break;
        case "match_flag_2":
          return "Match (Flag 2)";
          break;
        case "flag_1":
          return "Flag 1";
          break;
        case "flag_2":
          return "Flag 2";
          break;
        default:
          return "Invalid";
          break;
      }
    },
    analyzepagination() {
      this.dropdownKey = Math.random()
      if (this.currentpage === 1) {
        this.fetchMrRecoData();
      } else {
        this.currentpage = 1;
      }
    },
    refresh() {
      this.year_search = "";
      this.mr_number_search = "";
      this.amount_per_bank_search = "";
      this.adjusted_bank_amt_search = "";
      this.amount_per_mf_search = "";
      this.difference_search = "";
      this.difference_percentage_search = "";
      this.flag_search = "";
      this.reason_search = "";
      this.comments_search = "";
      this.adj_amount_search = "";
      this.adjustment_percentage_search = "";
      this.difference_percentage_below_search = "";
      this.difference_percentage_above_search = "";
      this.analyzepagination();
    },
    calculateAmount() {
      let amount = 0;
      amount = this.editObj.amount_per_bank - this.editObj.amount_per_mf;
      this.adj_amount = amount;
    },
    uploadExcel() {
      this.open = true;
    },
    EditReason(tr) {
      this.editObj = tr;
      if (tr.amount_per_bank > tr.amount_per_mf) {
        this.reasons = this.option1;
      } else if (tr.amount_per_bank < tr.amount_per_mf) {
        this.reasons = this.option2;
      }
      if (tr.reason !== "") {
        this.reason = tr.reason;
      }
      if (tr.comments !== "") {
        this.comments = tr.comments;
      }
      if (tr.adj_amount !== "") {
        this.adj_amount = tr.adj_amount;
      }
      this.adj_amount_disabled = true;
      this.comments_disabled = true;
      this.openEditOptions = true;
    },
    saveReason() {
      if (!this.checkValidation()) {
        return;
      }
      let url = `${constants.SERVER_API}updateMrRecoData`;
      let params = {
        comments: this.comments,
        reason: this.reason,
        adj_amount: this.adj_amount,
        mr_reco_id: this.editObj.id,
      };
      axios
        .post(url, params, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.handleNotification(response);
          if (response.data.status === "success") {
            this.fetchMrRecoData();
            this.openEditOptions = false;
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    checkValidation() {
      let blockReason = [
        "NEFT bounce back",
        "Candidate cheque bounce",
        "University EWB",
        "Petty reversals",
        "Other receipts",
      ];
      let validated = true;
      if (
        (this.comments === "" || this.comments === null) &&
        blockReason.indexOf(this.reason) !== -1
      ) {
        this.$vs.notify({
          title: "Enter comments",
          text: "Enter comments to save the data",
          color: "warning",
        });
        validated = false;
      }

      if (
        (this.adj_amount === "" || this.adj_amount === null) &&
        this.reason !== "Suspense"
      ) {
        this.$vs.notify({
          title: "Adjustment amount is required",
          text: "Adjustment amount is required to save the data",
          color: "warning",
        });
        validated = false;
      }

      if (this.editObj.amount_per_bank > this.editObj.amount_per_mf) {
        if (parseInt(this.adj_amount) < 0) {
          this.$vs.notify({
            title: "Enter a positive amount",
            text: "Positive integer is required to save the data",
            color: "warning",
          });
          validated = false;
        }
      }

      if (this.editObj.amount_per_bank < this.editObj.amount_per_mf) {
        if (parseInt(this.adj_amount) > 0) {
          this.$vs.notify({
            title: "Enter a negative amount",
            text: "Negative integer is required to save the data",
            color: "warning",
          });
          validated = false;
        }
      }

      return validated;
    },
    ProcessExcel(data) {
      var workbook = XLSX.read(data, {
        type: "binary",
      });

      //Fetch the name of First Sheet.
      var firstSheet = workbook.SheetNames[0];

      //Read all rows from First Sheet into an JSON array.
      var excelRows = XLSX.utils.sheet_to_row_object_array(
        workbook.Sheets[firstSheet]
      );

      //Create a HTML Table element.
      var table = document.createElement("table");
      table.border = "1";

      //Add the header row.
      var row = table.insertRow(-1);

      //Add the header cells.
      var headerCell = document.createElement("TH");
      headerCell.innerHTML = "Id";
      row.appendChild(headerCell);

      headerCell = document.createElement("TH");
      headerCell.innerHTML = "Name";
      row.appendChild(headerCell);

      headerCell = document.createElement("TH");
      headerCell.innerHTML = "Country";
      row.appendChild(headerCell);

      //Add the data rows from Excel file.
      for (var i = 0; i < excelRows.length; i++) {
        //Add the data row.
        var row = table.insertRow(-1);

        //Add the data cells.
        var cell = row.insertCell(-1);
        cell.innerHTML = excelRows[i].Id;

        cell = row.insertCell(-1);
        cell.innerHTML = excelRows[i].Name;

        cell = row.insertCell(-1);
        cell.innerHTML = excelRows[i].Country;
      }

      var dvExcel = document.getElementById("dvExcel");
      dvExcel.innerHTML = "";
      dvExcel.appendChild(table);
    },
    Upload() {
      var fileUpload = document.getElementById("fileUpload");
      // console.log("loading start");
      let processedExcel = [];
      var regex = /^([a-zA-Z0-9\s_\\.\-:])+(.xls|.xlsx)$/;
      if (regex.test(fileUpload.value.toLowerCase())) {
        if (typeof FileReader != "undefined") {
          var reader = new FileReader();
          var self = this;
          // console.log(this);
          if (reader.readAsBinaryString) {
            reader.onload = function(e) {
              var workbook = XLSX.read(e.target.result, {
                type: "binary",
              });
              var firstSheet = workbook.SheetNames[0];
              var excelRows = XLSX.utils.sheet_to_row_object_array(
                workbook.Sheets[firstSheet],
                { raw: false }
              );
              let processedrows = [];
              excelRows.forEach((rows) => {
                // console.log(rows);
                if (rows.year === undefined) {
                  rows.year = "";
                }
                if (rows.mr === undefined) {
                  rows.mr = "";
                }
                if (rows.count_per_bank === undefined) {
                  rows.count_per_bank = "";
                }
                if (rows.amount_per_bank === undefined) {
                  rows.amount_per_bank = "";
                }
                processedrows.push(rows);
              });
              processedExcel = processedrows;
              self.uploadRowsToServer(processedExcel);
            };
            reader.readAsBinaryString(fileUpload.files[0]);
          } else {
            reader.onload = function(e) {
              var data = "";
              var bytes = new Uint8Array(e.target.result);
              for (var i = 0; i < bytes.byteLength; i++) {
                data += String.fromCharCode(bytes[i]);
              }
              var workbook = XLSX.read(data, {
                type: "binary",
              });
              var firstSheet = workbook.SheetNames[0];
              var excelRows = XLSX.utils.sheet_to_row_object_array(
                workbook.Sheets[firstSheet],
                { date_format: "dd/mm/yyyy" }
              );
              // console.log(excelRows);
            };
            reader.readAsArrayBuffer(fileUpload.files[0]);
          }
        } else {
          alert("This browser does not support HTML5.");
        }
      } else {
        alert("Please upload a valid Excel file.");
      }
    },
    fetchMrRecoData() {
      let url = `${constants.SERVER_API}getMrRecoData`;
      let params = {
        page: this.currentPage,
        year: this.year_search,
        mr: this.mr_number_search,
        amount_per_bank: this.amount_per_bank_search,
        adjusted_bank_amt: this.adjusted_bank_amt_search,
        amount_per_mf: this.amount_per_mf_search,
        difference: this.difference_search,
        difference_percentage: this.difference_percentage_search,
        flag: this.flag_search,
        reason: this.reason_search,
        comments: this.comments_search,
        adj_amount: this.adj_amount_search,
        adj_percentage: this.adjustment_percentage_search,
        difference_percentage_above: this.difference_percentage_above_search,
        difference_percentage_below: this.difference_percentage_below_search,
      };
      axios
        .get(url, {
          params: params,
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status !== "success") {
            this.handleNotification(response);
            return;
          }
          this.mrRecoData = response.data.data.data;
          this.currentPage = response.data.data.current_page;
          this.totalPages = response.data.data.last_page;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    async uploadRowsToServer(ProccessedRows) {
      this.totalRows = ProccessedRows.length;
      for (let index = 0; index < ProccessedRows.length; index++) {
        const element = ProccessedRows[index];
        let response = await this.uploadRow(element);
        if (response.data.status !== "success") {
          this.$vs.notify({
            time: 20000,
            title: response.data.status,
            text: response.data.message,
            color: "danger",
            position: "top-center",
            click: () => {},
          });
          break;
        }
        self.completedpercentage = this.completedpercentage +=
          100 / ProccessedRows.length;
        this.completedRow = index + 1;
      }
    },
    uploadRow(data) {
      // let url = `${constants.SERVER_API}addNetEnquiry`
      // console.log(data);
      let url = `${constants.SERVER_API}mrRecoUpload`;
      return new Promise(async (resolve, reject) => {
        axios
          .post(url, data, {
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          })
          .then((response) => {
            // console.log(response);
            setTimeout(function() {
              resolve(response);
            }, 300);
          })
          .catch((error) => {
            reject(error);
            if (error.response) {
              console.log("error", error.response.status);
              if (error.response.status === 401) {
                this.ReDirect_to_login();
                // this.EmitEvent('close-popup-redirect')
              }
            }
          });
      });
    },
    timetoTimestamp(selected_date) {
      var timestamp = new Date(selected_date).setHours(0, 0, 0, 0) / 1000;
      return timestamp;
    },
    successUpload(e) {
      console.log("success upload", e);
      var reader = new FileReader();

      //For Browsers other than IE.
      if (reader.readAsBinaryString) {
        reader.onload = function(e) {
          console.log(e);
          // this.ProcessExcel(e.target.result);
        };
        // reader.readAsBinaryString(fileUpload.files[0]);
      }
      this.$vs.notify({
        color: "success",
        title: "Upload Success",
        text: "File successfully uploaded",
      });
    },
    errorUpload(e) {
      console.log("error", e);
    },
    changeUpload(e, files) {
      console.log("change", e);
      var reader = new FileReader();

      //For Browsers other than IE.
      if (reader.readAsBinaryString) {
        reader.onload = function(e) {
          console.log(e);
          // this.ProcessExcel(e.target.result);
        };
        // reader.readAsBinaryString(fileUpload.files[0]);
      }
      // this.process;
      // console.log("result", this.saveFile);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/vuesax/pages/dropdown.scss";
</style>
